@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
}
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background: #111111;
}
.loader-img__spin {
  width: 150px;
  animation: rotate-center 1.8s ease-in-out infinite both;
}