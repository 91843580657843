/* GENERAL CSS */
* {
  font-family: 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
  box-sizing: border-box;
  /* border: 1px solid blue; */ }
html {
  scroll-behavior: smooth;
  cursor: url(./assets/cursor/pepe-cursor.png), auto;
}
body::-webkit-scrollbar {
  width: 0.65rem; 
}

body::-webkit-scrollbar-track {
  background: #111111;
}

body::-webkit-scrollbar-thumb {
  background: #3e3e3e;
  border-radius: 20px;
}
body::-webkit-scrollbar-thumb:hover {
  background: #eeaf0e;
}
